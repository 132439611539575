<template>
    <div class="test-wrap">
        <div class="filter">
            <div class="filter__title">Filter <span></span></div>
            <form action="#" class="filter--wrap">
                <div class="filter__parameters from-to">
                    <div class="filter__parameters__title">Price</div>
                    <div class="filter__parameters__from-to">
                        <div class="from">
                            <span>from</span>
                            <input type="number" placeholder="5000" min="0" v-model="price_start"/>
                        </div>
                        <div class="to">
                            <span>to</span>
                            <input type="number" placeholder="100 000" max="1000000" v-model="price_end"/>
                        </div>
                    </div>
                </div>

                <div v-if="categories.length" class="filter__parameters checkbox">
                    <div class="filter__parameters__title">Product category</div>
                    <ul class="filter__parameters__parameter">
                        <li v-for="(cat, key) in categories" :key="`cat_${key}`">
                            <a :href="(category.parent) ? `/category/${category.parent.slug}/${cat.slug}` : `/category/${cat.slug}`"
                               :class="(cat.id === category.id) ? 'is-active' : ''">
                                <span>{{ cat.title }}</span>
                            </a>
                        </li>
                    </ul>
                </div>

                <div v-for="(filter, fk) in filters" class="filter__parameters checkbox" :key="`filter_${fk}`">
                    <div class="filter__parameters__title">{{ filter.title }}</div>
                    <ul class="filter__parameters__parameter">
                        <li v-for="(value, vk) in filter.values" :key="`value_${vk}`">
                            <template v-if="filter.type === 'color'">
                                <label class="control control-checkbox">
                                    <input @change="setSelectedFilters(value.id)" type="checkbox"/>
                                    <span class="control_indicator"></span>
                                </label>
                                <span v-if="value.thumbnail_id"
                                      class="color filter-color"
                                      :style="`background-image: url('/storage/${value.thumbnail.url}')`"></span>
                                <span v-else class="color filter-color"
                                      :style="`background: ${value.color}`"></span>
                                <span class="filter-title">
                                    {{ value.title }}
                                </span>
                            </template>
                            <template v-else>
                                <label class="control control-checkbox">
                                    <input @change="setSelectedFilters(value.id)" type="checkbox"/>
                                    <span class="control_indicator"></span>
                                </label>
                                <span v-html="value.title"></span>
                            </template>
                        </li>
                    </ul>
                </div>
                <button type="submit" class="filter__parameters__apply" @click="filterProducts">Apply</button>
                <div class="filter__parameters__clear" @click="resetFilters">Clear filter</div>
            </form>
        </div>
        <main>
            <div class="container-mian">
                <div class="breadcrumbs">
                    <ul>
                        <li><a href="/">Main</a></li>
                        <li><a href="/katalog">Catalog</a></li>
                        <li v-if="category.parent">
                            <a :href="`/category/${category.parent.slug}`">{{ category.parent.title }}</a>
                        </li>
                        <li><span>{{ category.title }}</span></li>
                    </ul>
                </div>
                <div class="category">
                    <h2>{{ category.title }}</h2>
                    <div class="filter-face"></div>
                    <div class="sorting">
                        <div class="sorting__title">Sort by:</div>
                        <ul class="sorting__slect">
                            <li @click="order = 0"
                                :class="(order === 0) ? 'is-active' : ''">Newest
                            </li>
                            <li @click="order = 1"
                                :class="(order === 1) ? 'is-active' : ''">Popular
                            </li>
                            <li @click="order = 2"
                                :class="(order === 2) ? 'is-active' : ''">From cheap to expensive
                            </li>
                            <li @click="order = 3"
                                :class="(order === 3) ? 'is-active' : ''">From expensive to cheap
                            </li>
                        </ul>
                        <v-select v-model="order" :options="options" label="title" :clearable="false"
                                  :reduce="country => country.value" class="catalog"></v-select>

                    </div>
                    <div class="products" v-if="products">
                        <div v-for="product in products" :key="`product_${product.id}`"
                             :class="(product.sale_price)?'product-item discount':'product-item'">

                            <div v-if="product.sale_price" class="product-item__discount">
                                {{ 100 - (product.sale_price * 100 / product.price).toFixed() }}%
                            </div>

                            <a :href="`/product/${product.slug}`" class="product-item__img">
                                <img :src="product.thumbnail" alt="roducts-item-1">
                                <img class="img-hover" :src="product.thumbnail_hover"
                                     alt="products-item-1">
                            </a>

                            <div v-if="product.category" class="product-item__category">
                                {{ product.category.title }}
                            </div>

                            <a :href="`/product/${product.slug}`" class="product-item__name">
                                {{ product.title }}
                            </a>

                            <div class="product-item__main-info">
                                <div class="product-item__main-info__price">
                                    <div
                                        :class="(product.sale_price)?'product-item__main-info__price__title discount':'product-item__main-info__price__title'">
                                        Price:
                                        <span v-if="product.sale_price">
                                            {{ product.price }} <i class="fas fa-euro-sign"></i>
                                        </span>
                                    </div>
                                    <div
                                        :class="(product.sale_price)?'product-item__main-info__price__sum discount':'product-item__main-info__price__sum'">
                                        {{ (product.sale_price) ? product.sale_price : product.price }}
                                        <i class="fas fa-euro-sign"></i>
                                    </div>
                                </div>
                                <a :href="`/product/${product.slug}`" class="product-item__main-info__buy">
                                    <svg width="29" height="29" viewBox="0 0 29 29" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M5.96193 2.84C5.71046 2.83945 5.46805 2.86796 5.2377 2.92216C4.92193 2.22506 4.50482 1.59964 4.03042 1.12448C3.22523 0.317989 1.95532 0 0.197388 0V2.83639C1.27129 2.83639 1.88926 2.99114 2.02801 3.13011C2.56319 3.66615 3.02922 4.7553 3.02922 5.67278L3.04345 5.87335L4.43086 15.6009C2.10938 15.7215 0.310941 17.5272 0.199127 19.7845L0.197388 21.2729C0.334478 23.6044 2.12693 25.3978 4.36476 25.5252L4.6862 25.5257C5.26882 27.1791 6.84271 28.3639 8.69288 28.3639C10.5424 28.3639 12.1158 27.18 12.6989 25.5275H16.0142C16.5973 27.18 18.1707 28.3639 20.0202 28.3639C22.3662 28.3639 24.2679 26.4591 24.2679 24.1093C24.2679 21.7596 22.3662 19.8547 20.0202 19.8547C18.1707 19.8547 16.5973 21.0387 16.0142 22.6911H12.6989C12.1158 21.0387 10.5424 19.8547 8.69288 19.8547C6.84338 19.8547 5.26996 21.0387 4.68684 22.6911H4.44513C3.72303 22.6477 3.07529 21.9996 3.02668 21.188L3.02922 19.8547C3.0674 19.1191 3.71071 18.4748 4.5153 18.4348L7.31051 18.4355L7.33077 18.4365H21.6016L21.8106 18.3701C22.8841 18.0289 23.7435 17.2192 24.1505 16.171L24.2996 15.8753L24.7843 14.9136C25.2857 13.9181 25.7871 12.9208 26.2757 11.9466C27.4725 9.56016 28.2251 8.04353 28.3918 7.6711C29.2478 5.75852 27.5138 4.28645 25.7415 4.2551L5.96193 2.84ZM21.1093 15.5995H7.43648C7.34556 15.5742 7.27628 15.4971 7.26272 15.401L5.87527 5.67325L25.5326 7.0832C25.1886 7.78577 24.5634 9.04064 23.7448 10.6729L23.7243 10.7138C23.2571 11.6454 22.7563 12.6413 22.2556 13.6355L21.7716 14.596L21.5881 14.9597L21.519 15.1205C21.4451 15.3269 21.2982 15.4965 21.1093 15.5995ZM20.0198 25.5272C20.8018 25.5272 21.4358 24.8923 21.4358 24.109C21.4358 23.3258 20.8018 22.6908 20.0198 22.6908C19.2378 22.6908 18.6039 23.3258 18.6039 24.109C18.6039 24.8923 19.2378 25.5272 20.0198 25.5272ZM10.109 24.109C10.109 24.8923 9.47512 25.5272 8.69315 25.5272C7.91117 25.5272 7.27725 24.8923 7.27725 24.109C7.27725 23.3258 7.91117 22.6908 8.69315 22.6908C9.47512 22.6908 10.109 23.3258 10.109 24.109Z"
                                            fill="#F2994A"
                                        />
                                    </svg>
                                </a>
                            </div>

                            <div class="product-item__second-info" v-if="product.data.length">
                                <div v-for="(filter, filter_key) in product.data" :key="filter_key"
                                     class="product-item__second-info__colors">
                                    <div class="title">{{ filter.title }}:</div>
                                    <div v-if="filter.type === 'color'" class="color-wrap">
                                        <template v-for="(filter_value, filter_value_key) in filter.values">
                                            <template v-if="filter_value_key < 4">
                                                <div v-if="filter_value.thumbnail" class="color" :key="filter_value_key"
                                                     :style="`background: url(/storage/${filter_value.thumbnail.url}); background-size: cover`">

                                                </div>
                                                <div v-else class="color" :key="filter_value_key"
                                                     :style="`background: ${filter_value.color}`"></div>
                                            </template>
                                            <template v-else>
                                                <div class="color color--all" :key="filter_value_key">...</div>
                                            </template>
                                        </template>
                                    </div>
                                    <p v-else>
                                        {{ filter.values.map(e => e.title).join(", ") }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <paginate
                        v-if="pageCount > 1"
                        v-model="page"
                        :page-count="pageCount"
                        prev-text="<"
                        next-text=">"
                        container-class="pagination"
                        active-class="is-active"
                        page-class="pagination__number pagination--section"
                        prev-class="pagination__left pagination--section"
                        next-class="pagination__right pagination--section"
                    >
                    </paginate>
                </div>
                <div class="text-info">
                    <div class="text-info__text-wrap" v-html="category.content"></div>
                    <div class="text-info__watch-more" data-text="Show more" data-hidden="Hide"></div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
export default {
    name: "Catalog",
    props: ['baseCategory', 'baseCategories', 'baseFilters'],
    data: () => ({
        products: [],
        page: 1,
        pageCount: 1,
        category: {},
        categories: [],
        filters: [],
        price_start: null,
        price_end: null,
        order: 1,
        selectedFilters: [],
        options: [
            {
                title: 'Newest',
                value: 0
            },
            {
                title: 'Popular',
                value: 1
            },
            {
                title: 'From cheap to expensive',
                value: 2
            },
            {
                title: 'From expensive to cheap',
                value: 3
            }
        ]
    }),
    created() {
        this.category = JSON.parse(this.baseCategory)
        this.categories = JSON.parse(this.baseCategories)
        this.filters = JSON.parse(this.baseFilters)
    },
    mounted() {
        this.getProducts();
    },
    methods: {
        getProducts() {
            let query = `/catalog/products?category=${this.category.id}&page=${this.page}&order=${this.order}`;
            if (this.selectedFilters.length) query += '&filters=' + this.selectedFilters.join(',');
            if (this.price_start) query += '&price_start=' + this.price_start;
            if (this.price_end) query += '&price_end=' + this.price_end;

            this.$http
                .get(query)
                .then(res => {
                    this.products = res.data.data
                    this.pageCount = res.data.meta.last_page
                })
                .catch(error => error.message)
        },
        setSelectedFilters(id) {
            let index = this.selectedFilters.findIndex(el => el === id);
            (index > -1) ? this.selectedFilters.splice(index, 1) : this.selectedFilters.push(id);
        },
        filterProducts() {
            this.page = 1;
            this.getProducts()
        },
        resetFilters() {
            this.selectedFilters = [];
            this.price_start = null;
            this.price_end = null;

            this.getProducts()
        }
    },
    watch: {
        page: function () {
            this.getProducts()
        },
        order: function () {
            this.getProducts()
        }
    }
}
</script>

<style scoped>
.filter-color {
    width: 25px;
    height: 25px;
    border-radius: 4px;
    margin-right: 2%;
    background-size: cover;
    border: 1px solid #e0e0e0
}

.filter-title {
    width: 70%;
}
</style>
