var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "test-wrap" }, [
    _c("div", { staticClass: "filter" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "form",
        { staticClass: "filter--wrap", attrs: { action: "#" } },
        [
          _c("div", { staticClass: "filter__parameters from-to" }, [
            _c("div", { staticClass: "filter__parameters__title" }, [
              _vm._v("Price"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "filter__parameters__from-to" }, [
              _c("div", { staticClass: "from" }, [
                _c("span", [_vm._v("from")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.price_start,
                      expression: "price_start",
                    },
                  ],
                  attrs: { type: "number", placeholder: "5000", min: "0" },
                  domProps: { value: _vm.price_start },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.price_start = $event.target.value
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "to" }, [
                _c("span", [_vm._v("to")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.price_end,
                      expression: "price_end",
                    },
                  ],
                  attrs: {
                    type: "number",
                    placeholder: "100 000",
                    max: "1000000",
                  },
                  domProps: { value: _vm.price_end },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.price_end = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm._l(_vm.filters, function (filter, fk) {
            return _c(
              "div",
              {
                key: `filter_${fk}`,
                staticClass: "filter__parameters checkbox",
              },
              [
                _c("div", { staticClass: "filter__parameters__title" }, [
                  _vm._v(_vm._s(filter.title)),
                ]),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "filter__parameters__parameter" },
                  _vm._l(filter.values, function (value, vk) {
                    return _c(
                      "li",
                      { key: `value_${vk}` },
                      [
                        filter.type === "color"
                          ? [
                              _c(
                                "label",
                                { staticClass: "control control-checkbox" },
                                [
                                  _c("input", {
                                    attrs: { type: "checkbox" },
                                    on: {
                                      change: function ($event) {
                                        return _vm.setSelectedFilters(value.id)
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", {
                                    staticClass: "control_indicator",
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              value.thumbnail_id
                                ? _c("span", {
                                    staticClass: "color filter-color",
                                    style: `background-image: url('/storage/${value.thumbnail.url}')`,
                                  })
                                : _c("span", {
                                    staticClass: "color filter-color",
                                    style: `background: ${value.color}`,
                                  }),
                              _vm._v(" "),
                              _c("span", { staticClass: "filter-title" }, [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(value.title) +
                                    "\n                            "
                                ),
                              ]),
                            ]
                          : [
                              _c(
                                "label",
                                { staticClass: "control control-checkbox" },
                                [
                                  _c("input", {
                                    attrs: { type: "checkbox" },
                                    on: {
                                      change: function ($event) {
                                        return _vm.setSelectedFilters(value.id)
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", {
                                    staticClass: "control_indicator",
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(value.title))]),
                            ],
                      ],
                      2
                    )
                  }),
                  0
                ),
              ]
            )
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "filter__parameters__apply",
              attrs: { type: "submit" },
              on: { click: _vm.filterProducts },
            },
            [_vm._v("Apply")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "filter__parameters__clear",
              on: { click: _vm.resetFilters },
            },
            [_vm._v("Clear filter")]
          ),
        ],
        2
      ),
    ]),
    _vm._v(" "),
    _c("main", [
      _c("div", { staticClass: "container-mian" }, [
        _c("div", { staticClass: "breadcrumbs" }, [
          _c("ul", [
            _vm._m(1),
            _vm._v(" "),
            _c("li", [_c("span", [_vm._v(_vm._s(_vm.salePage.title))])]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "category" },
          [
            _c("h2", [_vm._v(_vm._s(_vm.salePage.title))]),
            _vm._v(" "),
            _c("div", { staticClass: "filter-face" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "sorting" },
              [
                _c("div", { staticClass: "sorting__title" }, [
                  _vm._v("Sort by:"),
                ]),
                _vm._v(" "),
                _c("ul", { staticClass: "sorting__slect" }, [
                  _c(
                    "li",
                    {
                      class: _vm.order === 0 ? "is-active" : "",
                      on: {
                        click: function ($event) {
                          _vm.order = 0
                        },
                      },
                    },
                    [_vm._v("Newest\n                        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      class: _vm.order === 1 ? "is-active" : "",
                      on: {
                        click: function ($event) {
                          _vm.order = 1
                        },
                      },
                    },
                    [_vm._v("Popular\n                        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      class: _vm.order === 2 ? "is-active" : "",
                      on: {
                        click: function ($event) {
                          _vm.order = 2
                        },
                      },
                    },
                    [
                      _vm._v(
                        "From cheap to expensive\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      class: _vm.order === 3 ? "is-active" : "",
                      on: {
                        click: function ($event) {
                          _vm.order = 3
                        },
                      },
                    },
                    [
                      _vm._v(
                        "From expensive to cheap\n                        "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("v-select", {
                  staticClass: "catalog",
                  attrs: {
                    options: _vm.options,
                    label: "title",
                    clearable: false,
                    reduce: (country) => country.value,
                  },
                  model: {
                    value: _vm.order,
                    callback: function ($$v) {
                      _vm.order = $$v
                    },
                    expression: "order",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.products
              ? _c(
                  "div",
                  { staticClass: "products" },
                  _vm._l(_vm.products, function (product) {
                    return _c(
                      "div",
                      {
                        key: `product_${product.id}`,
                        class: product.sale_price
                          ? "product-item discount"
                          : "product-item",
                      },
                      [
                        product.sale_price
                          ? _c(
                              "div",
                              { staticClass: "product-item__discount" },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      100 -
                                        (
                                          (product.sale_price * 100) /
                                          product.price
                                        ).toFixed()
                                    ) +
                                    "%\n                        "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "product-item__img",
                            attrs: { href: `/product/${product.slug}` },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: product.thumbnail,
                                alt: "roducts-item-1",
                              },
                            }),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "img-hover",
                              attrs: {
                                src: product.thumbnail_hover,
                                alt: "products-item-1",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        product.category
                          ? _c(
                              "div",
                              { staticClass: "product-item__category" },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(product.category.title) +
                                    "\n                        "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "product-item__name",
                            attrs: { href: `/product/${product.slug}` },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(product.title) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "product-item__main-info" }, [
                          _c(
                            "div",
                            { staticClass: "product-item__main-info__price" },
                            [
                              _c(
                                "div",
                                {
                                  class: product.sale_price
                                    ? "product-item__main-info__price__title discount"
                                    : "product-item__main-info__price__title",
                                },
                                [
                                  _vm._v(
                                    "\n                                    Price:\n                                    "
                                  ),
                                  product.sale_price
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(product.price) +
                                            " "
                                        ),
                                        _c("i", {
                                          staticClass: "fas fa-euro-sign",
                                        }),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  class: product.sale_price
                                    ? "product-item__main-info__price__sum discount"
                                    : "product-item__main-info__price__sum",
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        product.sale_price
                                          ? product.sale_price
                                          : product.price
                                      ) +
                                      "\n                                    "
                                  ),
                                  _c("i", { staticClass: "fas fa-euro-sign" }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "product-item__main-info__buy",
                              attrs: { href: `/product/${product.slug}` },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    width: "29",
                                    height: "29",
                                    viewBox: "0 0 29 29",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      "fill-rule": "evenodd",
                                      "clip-rule": "evenodd",
                                      d: "M5.96193 2.84C5.71046 2.83945 5.46805 2.86796 5.2377 2.92216C4.92193 2.22506 4.50482 1.59964 4.03042 1.12448C3.22523 0.317989 1.95532 0 0.197388 0V2.83639C1.27129 2.83639 1.88926 2.99114 2.02801 3.13011C2.56319 3.66615 3.02922 4.7553 3.02922 5.67278L3.04345 5.87335L4.43086 15.6009C2.10938 15.7215 0.310941 17.5272 0.199127 19.7845L0.197388 21.2729C0.334478 23.6044 2.12693 25.3978 4.36476 25.5252L4.6862 25.5257C5.26882 27.1791 6.84271 28.3639 8.69288 28.3639C10.5424 28.3639 12.1158 27.18 12.6989 25.5275H16.0142C16.5973 27.18 18.1707 28.3639 20.0202 28.3639C22.3662 28.3639 24.2679 26.4591 24.2679 24.1093C24.2679 21.7596 22.3662 19.8547 20.0202 19.8547C18.1707 19.8547 16.5973 21.0387 16.0142 22.6911H12.6989C12.1158 21.0387 10.5424 19.8547 8.69288 19.8547C6.84338 19.8547 5.26996 21.0387 4.68684 22.6911H4.44513C3.72303 22.6477 3.07529 21.9996 3.02668 21.188L3.02922 19.8547C3.0674 19.1191 3.71071 18.4748 4.5153 18.4348L7.31051 18.4355L7.33077 18.4365H21.6016L21.8106 18.3701C22.8841 18.0289 23.7435 17.2192 24.1505 16.171L24.2996 15.8753L24.7843 14.9136C25.2857 13.9181 25.7871 12.9208 26.2757 11.9466C27.4725 9.56016 28.2251 8.04353 28.3918 7.6711C29.2478 5.75852 27.5138 4.28645 25.7415 4.2551L5.96193 2.84ZM21.1093 15.5995H7.43648C7.34556 15.5742 7.27628 15.4971 7.26272 15.401L5.87527 5.67325L25.5326 7.0832C25.1886 7.78577 24.5634 9.04064 23.7448 10.6729L23.7243 10.7138C23.2571 11.6454 22.7563 12.6413 22.2556 13.6355L21.7716 14.596L21.5881 14.9597L21.519 15.1205C21.4451 15.3269 21.2982 15.4965 21.1093 15.5995ZM20.0198 25.5272C20.8018 25.5272 21.4358 24.8923 21.4358 24.109C21.4358 23.3258 20.8018 22.6908 20.0198 22.6908C19.2378 22.6908 18.6039 23.3258 18.6039 24.109C18.6039 24.8923 19.2378 25.5272 20.0198 25.5272ZM10.109 24.109C10.109 24.8923 9.47512 25.5272 8.69315 25.5272C7.91117 25.5272 7.27725 24.8923 7.27725 24.109C7.27725 23.3258 7.91117 22.6908 8.69315 22.6908C9.47512 22.6908 10.109 23.3258 10.109 24.109Z",
                                      fill: "#F2994A",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        product.data.length
                          ? _c(
                              "div",
                              { staticClass: "product-item__second-info" },
                              _vm._l(
                                product.data,
                                function (filter, filter_key) {
                                  return _c(
                                    "div",
                                    {
                                      staticClass:
                                        "product-item__second-info__colors",
                                    },
                                    [
                                      _c("div", { staticClass: "title" }, [
                                        _vm._v(_vm._s(filter.title) + ":"),
                                      ]),
                                      _vm._v(" "),
                                      filter.type === "color"
                                        ? _c(
                                            "div",
                                            { staticClass: "color-wrap" },
                                            [
                                              _vm._l(
                                                filter.values,
                                                function (
                                                  filter_value,
                                                  filter_value_key
                                                ) {
                                                  return [
                                                    filter_value_key < 4
                                                      ? [
                                                          filter_value.thumbnail
                                                            ? _c("div", {
                                                                staticClass:
                                                                  "color",
                                                                style: `background: url(/storage/${filter_value.thumbnail.url}); background-size: cover`,
                                                              })
                                                            : _c("div", {
                                                                staticClass:
                                                                  "color",
                                                                style: `background: ${filter_value.color}`,
                                                              }),
                                                        ]
                                                      : [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "color color--all",
                                                            },
                                                            [_vm._v("...")]
                                                          ),
                                                        ],
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        : _c("p", [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  filter.values
                                                    .map((e) => e.title)
                                                    .join(", ")
                                                ) +
                                                "\n                                "
                                            ),
                                          ]),
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.pageCount > 1
              ? _c("paginate", {
                  attrs: {
                    "page-count": _vm.pageCount,
                    "prev-text": "<",
                    "next-text": ">",
                    "container-class": "pagination",
                    "active-class": "is-active",
                    "page-class": "pagination__number pagination--section",
                    "prev-class": "pagination__left pagination--section",
                    "next-class": "pagination__right pagination--section",
                  },
                  model: {
                    value: _vm.page,
                    callback: function ($$v) {
                      _vm.page = $$v
                    },
                    expression: "page",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "text-info" }, [
          _c("div", {
            staticClass: "text-info__text-wrap",
            domProps: { innerHTML: _vm._s(_vm.salePage.content) },
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "text-info__watch-more",
            attrs: { "data-text": "Show more", "data-hidden": "Hide" },
          }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "filter__title" }, [
      _vm._v("Filter "),
      _c("span"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [_c("a", { attrs: { href: "/" } }, [_vm._v("Main")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }