window.Vue = require('vue');
import jQuery from 'jquery'

window.jQuery = jQuery;
window.$ = jQuery;

import Swiper from 'swiper';
import Inputmask from "inputmask";
import axios from 'axios'
import Paginate from 'vuejs-paginate'
import vSelect from 'vue-select'
import suggestion from 'suggestions-jquery'

require('@fortawesome/fontawesome-free/js/all.js')
require("jquery-mousewheel");
require('malihu-custom-scrollbar-plugin');
require('./_libs/formstyler.min.js')

axios.interceptors.request.use(function (config) {
    config.headers['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    return config;
});

Vue.prototype.$http = axios

import Catalog from "./components/Catalog";
import Sale from "./components/Sale";

Vue.component('paginate', Paginate)
Vue.component('Catalog', Catalog)
Vue.component('Sale', Sale)
Vue.component('v-select', vSelect)
let sidebarProcessing = false;

let Cart = {
    add: function (data, callback) {
        axios.post('/add-to-cart', data)
            .then((res) => {
                callback(res.data)
            })
    },
    update: function (data, callback) {
        axios.post('/update-in-cart', data)
            .then((res) => {
                callback(res.data)
            })
    },
    coupon: function (data, callback, errorCallback) {
        axios.post('/coupon', data)
            .then((res) => {
                callback(res.data)
            })
            .catch(error => errorCallback(error))
    },
    remove: function (data, callback) {
        axios.post('/remove-from-cart', data)
            .then((res) => {
                callback(res.data)
            })
    }
};

function calculateProductPrice() {
    let productWrapper = $('.card-product'),
        colorWrapper = $('.card-product__item__characteristic__color'),
        otherPricesWrappers = $('.card-product__item__characteristic__text'),
        paramWrapper = $('.card-product__item__characteristic__materials'),
        basePrice = parseInt(productWrapper.data('price'));


    if (colorWrapper.length)
        basePrice += parseInt(colorWrapper.find('.colors__color.is-active').data('price'));

    if (otherPricesWrappers.length)
        $.each(otherPricesWrappers, function (key, el) {
            basePrice += parseInt($(el).find('.selected.sel').data('price'));
        })

    if (paramWrapper.length)
        basePrice += parseInt(paramWrapper.find('.selected.sel').data('price'));

    $('.price .price__cost p').html(basePrice * $('[name=qty]').val() + '<i class="fas fa-euro-sign"></i>')
}

function calculateGroupedProductPrice() {
    let selectedElementsButtons = $('.system-element--cost--button.is-active'),
        price = 0;

    $.each(selectedElementsButtons, (key, el) => {
        let amount = $(el).closest('.system-element').find('input').first().val();
        price += amount * $(el).data('price')
    })

    $('.card-product__item__characteristic__price')
        .find('.price__cost p')
        .html(`${price}<i class="fas fa-euro-sign"></i>`)
}

function generateUniqueId(btn) {
    let productWrapper = btn.closest('.card-product'),
        colorWrapper = $('.card-product__item__characteristic__color'),
        otherPricesWrappers = $('.card-product__item__characteristic__text'),
        paramWrapper = $('.card-product__item__characteristic__materials'),
        baseId = parseInt(productWrapper.data('id'));


    if (colorWrapper.length)
        baseId = baseId + '-' + parseInt(colorWrapper.find('.colors__color.is-active').data('id'));

    if (otherPricesWrappers.length)
        $.each(otherPricesWrappers, function (key, el) {
            baseId = baseId + '-' + parseInt($(el).find('.selected.sel').data('id'));
        })

    if (paramWrapper.length)
        baseId = baseId + '-' + parseInt(paramWrapper.find('.selected.sel').data('id'));

    return {
        id: baseId,
        qty: $('[name=qty]').val()
    };
}

function calculateCheckoutPrice() {
    let price = 0;
    $('.price-value').each((key, el) => {
        let priceString = el.getAttribute('data-price'),
            _price = (typeof priceString === 'string') ? priceString.replace(',', '') : priceString;
        _price = _price * 100;
        price += _price
    })

    $('.checkout__product-info__products__total__price')[0]
        .innerHTML = `${(price / 100).toLocaleString()}.00 <i class="fas fa-euro-sign"></i>`
}

function join(arr /*, separator */) {
    var separator = arguments.length > 1 ? arguments[1] : ", ";
    return arr.filter(function (n) {
        return n
    }).join(separator);
}

function formatCity(suggestion) {
    var address = suggestion.data;
    if (address.city_with_type === address.region_with_type) {
        return address.settlement_with_type || "";
    } else {
        return join([
            address.city_with_type,
            address.settlement_with_type]);
    }
}

$(document).ready(function () {

    let
        type = 'ADDRESS',
        token = "7208008fa4da25bc1e25e1edc16cf04ff20ef4d3",
        $region = $('#region'),
        $city = $('#city'),
        $address = $('#address');

    $region.suggestions({
        token: token,
        type: type,
        hint: false,
        bounds: "region"
    })

    $city.suggestions({
        token: token,
        type: type,
        hint: false,
        bounds: "city",
        constraints: $region,
        formatSelected: formatCity
    })

    $address.suggestions({
        token: token,
        type: type,
        hint: false,
        bounds: "street-house",
        constraints: $city,
        count: 15
    })

    $('svg > g').attr('clip-path', '')

    $(".catHoverable").mouseenter(function () {
        console.log($(this).data('hover'))
        $(this).find("path , rect").attr("fill", $(this).data('hover'))
    });
    $(".catHoverable").mouseleave(function () {
        console.log($(this).data('normal'))
        $(this).find("path , rect").attr("fill", $(this).data('normal'))
    });


    contactsSliderInitialization();
    /** cart logic */
    $('.system-element').on('click', () => calculateGroupedProductPrice())
    $('.buy__one-click').on('click', function (e) {
        e.preventDefault()
        $(this).addClass('is-active')
        $(this).siblings('.activeProductAdd-js').trigger('click')
    })
    $(".activeProductAdd-js.simple").on('click', function (e) {
        e.preventDefault();
        let uniqueId = [];

        uniqueId.push(generateUniqueId($(this)))

        Cart.add(uniqueId, function (res) {
            $('.header__right__basket').replaceWith(res.miniCart)
            $('.header-mob__basket').replaceWith(res.miniCartMobile)

            popUpActive();
            $(".pop-ups__product-add").addClass("is-active");

            if ($('.buy__one-click').hasClass('is-active')) {
                setTimeout(() => {
                    window.location.href = $('.buy__one-click').attr('href')
                }, 1500)
            }

            setTimeout(() => {
                $(".pop-ups__product-add").removeClass("is-active"), popUpClose()
            }, 2000);
        })
    });
    $(".activeProductAdd-js.complex").on('click', function (e) {
        e.preventDefault();
        let uniqueId = [];

        let activeSystemElements = $('.system-element--cost--button.is-active');

        $.each(activeSystemElements, function (key, el) {
            let base = $(el).closest('.system-element'),
                baseId = base.data('id');

            $.each($(base).find('.id-param'), function (_key, _el) {
                baseId = baseId + '-' + $(_el).data('id')
            })

            uniqueId.push({id: baseId, qty: base.find('input').val()})
        })

        Cart.add(uniqueId, function (res) {
            $('.header__right__basket').replaceWith(res.miniCart)
            $('.header-mob__basket').replaceWith(res.miniCartMobile)

            popUpActive();
            $(".pop-ups__product-add").addClass("is-active");

            if ($('.buy__one-click').hasClass('is-active')) {
                setTimeout(() => {
                    window.location.href = $('.buy__one-click').attr('href')
                }, 2000)
            }

            setTimeout(() => {
                $(".pop-ups__product-add").removeClass("is-active"), popUpClose()
            }, 2000);
        })


    });
    let basket = $('.basket');
    basket.on('click', '.quantity__plus', function () {
        let rowId = $(this).closest('.basket__products__item')
            .find('.basket__products__item__title__delete').first()
            .data('rowid');

        let quantityNum = $(this).closest(".basket__products__item").find(".quantity__number");

        Cart.update({rowId: rowId, qty: +quantityNum.val() + 1}, function (res) {
            $('.header__right__basket').replaceWith(res.miniCart)
            $('.header-mob__basket').replaceWith(res.miniCartMobile)
            $('.basket-internal').replaceWith(res.cart)
        })
    })
    basket.on('click', '.quantity__minus', function () {
        let rowId = $(this).closest('.basket__products__item')
            .find('.basket__products__item__title__delete').first()
            .data('rowid');

        let quantityNum = $(this).closest(".basket__products__item").find(".quantity__number");

        if (quantityNum.val() === 1) return;

        Cart.update({rowId: rowId, qty: +quantityNum.val() - 1}, function (res) {
            $('.header__right__basket').replaceWith(res.miniCart)
            $('.header-mob__basket').replaceWith(res.miniCartMobile)
            $('.basket-internal').replaceWith(res.cart)
        })
    })
    basket.on('click', '.basket__products__item__title__delete', function (e) {
        e.preventDefault()

        Cart.remove({rowId: $(this).data('rowid')}, function (res) {
            $('.header__right__basket').replaceWith(res.miniCart)
            $('.header-mob__basket').replaceWith(res.miniCartMobile)
            $('.basket-internal').replaceWith(res.cart)
        })
    })
    basket.on('click', '.basket__products__coupon button', function (e) {
        e.preventDefault();

        let coupon = $(this).prev().val();

        Cart.coupon({text: coupon}, function (res) {
            $('.header__right__basket').replaceWith(res.miniCart)
            $('.header-mob__basket').replaceWith(res.miniCartMobile)
            $('.basket-internal').replaceWith(res.cart)
        }, function (error) {
            alert(error.response.data.message)
        })
    })
    /** Cart logic ends here */

    /** Checkout logic */
    $('.checkout__contact-info__place__region').on('click', 'li', function (e) {
        e.preventDefault()
        let id = $(this).data('id');

        let citiesSelectWrapper = $('.checkout__contact-info__place__city');

        $.each(citiesSelectWrapper.find('.jq-selectbox'), function (key, el) {
            if ($(el).find('select').data('id') === id) {
                $(el).find('select').addClass('is-active').prop('disabled', false)
                    .trigger('refresh')
            } else {
                $(el).find('select').removeClass('is-active').prop('disabled', true)
                    .trigger('refresh')
            }
        })
    })
    $('input[name=delivery_method]').on('change', function () {
        let discountEl = $('.checkout__product-info__products__total-info__delivery').get(0);
        $(discountEl).attr('data-price', $(this).data('price'));
        $('.checkout__product-info__products__total-info__delivery__price')[0]
            .innerHTML = `${$(this).data('price').toLocaleString()}.00 <i class="fas fa-euro-sign"></i>`;

        setTimeout(() => {
            calculateCheckoutPrice()
        }, 200)
    })
    /** Checkout logic ends here */

    let phones = document.querySelectorAll("[type=tel]");
    Inputmask("+1 (999) 999-99-99").mask(phones);

    // @TODO пофиксить селект, не работает для vue компонентов
    $('select:not(.catalog)').styler();

    //сайт главная замена с большог на меленький
    if ($(window).width() <= 1024) {
        $(".sub-menu-small").mouseenter(function (e) {
            $("body").css('overflow', 'hidden');
        });
        $(".sub-menu-small").mouseleave(function (e) {
            $("body").css('overflow', '');
        });
    }
    //сайтбар большой открытие закрытие
    $(".sidebar__nav__wrapper__category").on('click', function (e) {

        if ($(this).hasClass("has-children") && !$(this).hasClass("is-active")) {
            e.preventDefault();
            e.stopPropagation();

            let _ = $(this);
            _.addClass('is-active').find('.sub-menu--wrap').css("z-index", 3);
            _.siblings().find('.sub-menu--wrap').css("z-index", 1);
            setTimeout(() => {
                _.find('.sub-menu--wrap').addClass('is-active')
            }, 150);

            setTimeout(() => {
                _.siblings().removeClass('is-active')
                _.siblings().find('.sub-menu--wrap').removeClass('is-active')
            }, 300);
        }
    });
    //сайтбар маленький открытие закрытие
    $(".sidebar-small__nav__wrapper__category").on('click', function (e) {

        if ($(this).hasClass("has-children") && !$(this).hasClass("is-active")) {
            e.preventDefault();
            e.stopPropagation();

            let _ = $(this);
            _.addClass('is-active').find('.sub-menu-small--wrap').css("z-index", 3);
            _.siblings().find('.sub-menu-small--wrap').css("z-index", 1);
            setTimeout(() => {
                _.find('.sub-menu-small--wrap').addClass('is-active')
            }, 150);

            setTimeout(() => {
                _.siblings().removeClass('is-active')
                _.siblings().find('.sub-menu-small--wrap').removeClass('is-active')
            }, 300);
        }
    });
    //закрытие меню в сайт баре
    $(".close-side-bar").on('click', function (e) {
        e.preventDefault();

        let subMenu = $(this).closest('.is-active');
        subMenu.removeClass('is-active');
        let catMenu = subMenu.closest('.is-active');
        setTimeout(() => {
            catMenu.removeClass('is-active');
        }, 50)
    });
    //моб меню
    $('ul.header-mob__caption').on('click', 'li:not(.is-active)', function () {
        $('body').addClass("of-hidden");
        $(this)
            .addClass('is-active').siblings().removeClass('is-active')
            .closest('div.header-mob').find('nav.header-mob__content').removeClass('is-active').eq($(this).index()).addClass('is-active');

    });
    $('ul.header-mob__caption').on('click', 'li.is-active', function () {
        $('body').removeClass("of-hidden");
        $(this).removeClass('is-active');
        $('nav.header-mob__content').removeClass('is-active');
    });
    //скрол в меню сайтбара
    $(".sub-menu--wrap .sub-menu, .sub-menu-small--wrap .sub-menu-small").mCustomScrollbar({
        theme: "dark",
        scrollInertia: 500,
    });
    //скрол
    $(".tabs__caption").mCustomScrollbar({
        theme: "dark",
        axis: "x",
        scrollInertia: 500,
    });
    //очищение фильтра
    $(".filter__parameters__clear").on('click', function (e) {
        $(this).closest("form")[0].reset();
    });
    //скритие фильтра на моб версии
    $(".filter--wrap").on('submit', function (e) {
        e.preventDefault();
        if ($(window).width() <= 767) {
            $(".filter__title span").removeClass("is-active");
            $(this).slideToggle();
            $('body , html').animate({scrollTop: 0}, 1500);
        }
    });
    //скритие фильтра на моб версии
    $(".filter--wrapper").on('submit', function (e) {
        e.preventDefault();
        $(".filter__title span").removeClass("is-active");
        $(this).slideToggle();
    });

    // $(".pop-ups--wrap form").on('submit', function (e) {
    //     e.preventDefault();
    //     $(this).find(".pop-ups__status").addClass("is-active");
    //     $(this)[0].reset();
    // });

    $(".contacts__write-us").on('submit', function (e) {
        e.preventDefault();

        axios.post(`/questions`, $(this).serialize())
            .then(() => {
                $(".contacts__write-us__status").addClass("is-active");
                $(this).closest("form")[0].reset();
            })
            .catch(error => console.error(error.response.data.message))
    });
    //появления посика на моб сайт баре
    $(".sidebar-small__search").on('click ', 'span', function (e) {
        // e.preventDefault();
        $(this).toggleClass("is-active");
        $(".wrap-input").toggleClass("is-active");
    });
    //выпадашка меню
    $(".header__left__nav").on('click ', 'li', function (e) {
        // e.preventDefault();
        $(this).toggleClass("is-active");
    });
    //выпадашка меню vj, dthcbz
    $(".menu ul li").on('click ', 'a', function (e) {
        $(this).closest(".menu ul li").find(".sub-menu").slideToggle();
        $(this).closest(".menu ul li").toggleClass("is-active");
    });
    //меню планшет
    $(".header__left__burger-menu").on('click ', function () {
        $(this).toggleClass("is-active");
        $(".header__left__nav").toggleClass("is-active");
    });

    $(".header__search__activ").on('click ', function () {
        $('.header__search').toggleClass("is-active");
    });
    $(".header__right__phone__img").on('click ', function () {
        $(".header__right__phone__info").toggleClass("is-active");
    });

    if ($('.text-info__text-wrap').text().length < 500) {
        $(".text-info__text-wrap").addClass('small');
        $('.text-info__watch-more').addClass('none');
    }

    $(".text-info__watch-more").on('click ', function () {
        $(".text-info").toggleClass("is-active");
    });

    $(".filter__title").on('click ', 'span', function (e) {
        $(this).toggleClass("is-active");
        $(".filter--wrap").slideToggle();
        $(".filter--wrapper").slideToggle();
    });

    //счетчик количетсва

    $(".quantity__minus").on('click', function (e) {
        let $quantityNum = $(this).closest(".quantity__input-wrap__input").find(".quantity__number");

        if ($quantityNum.val() > 1) {
            $quantityNum.val(+$quantityNum.val() - 1);
        }

        calculateProductPrice()
    });

    $(".quantity__plus").on('click', function (e) {
        let $quantityNum = $(this).closest(".quantity__input-wrap__input").find(".quantity__number");
        $quantityNum.val(+$quantityNum.val() + 1);
        calculateProductPrice()
    });
    // табы на карточке товара
    $('ul.tabs__caption').on('click', 'li:not(.is-active)', function () {
        $(this)
            .addClass('is-active').siblings().removeClass('is-active')
            .closest('div.card-product__info').find('div.card-product__info__content').removeClass('is-active').eq($(this).index()).addClass('is-active');
    });
    //табы контакты
    $('ul.nav-stores').on('click', 'li:not(.is-active)', function () {
        $(this)
            .addClass('is-active').siblings().removeClass('is-active')
            .closest('div.stores-tab__content').find('div.info-stores').removeClass('is-active').eq($(this).index()).addClass('is-active');
        contactsSliderInitialization();
    });
    //эелменты ситемы кнопка добавить и отменить
    $(".add").on('click', function (e) {
        $(this).closest(".system-element--cost--button").addClass("is-active");
    });
    $(".cancel").on('click', function (e) {
        $(this).closest(".system-element--cost--button").removeClass("is-active");
    });
    //работа с попапами
    $(".pop-ups--close").on('click', function (e) {
        popUpClose();
    });
    $(".activeCallMe-js").on('click', function (e) {
        popUpActive();
        $(".pop-ups__call-me").addClass("is-active")
    });

    $(".activeLeaveComment-js").on('click', function (e) {
        popUpActive();
        $(".pop-ups-leave-comment").addClass("is-active")
            .find('[name=product_id]')
            .val($(this).closest('.card-product__js').data('id'))
    });

    $(".activeGetDiscount-js").on('click', function (e) {
        popUpActive();
        $(".pop-ups__get-discount").addClass("is-active")
    });

    //slider

    if ($('.main-slider__content__right').length && $('.main-slider__content__left').length) {

        var galleryThumbs = new Swiper('.main-slider__content__right', {
            spead: 1000,
            simulateTouch: false,
            // loop: true,
            // pagination: {
            //     el: '.swiper-pagination',
            //     type: 'fraction',
            //     formatFractionCurrent: function (number) {
            //         if (number < 10) {
            //             number = '0' + number;
            //         }
            //         return number;
            //     },
            //     formatFractionTotal: function (number) {
            //         if (number < 10) {
            //             number = '0' + number;
            //         }
            //         return number;
            //     },
            // },
        });
        var galleryTop = new Swiper('.main-slider__content__left', {
            spead: 1000,
            simulateTouch: false,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            thumbs: {
                swiper: galleryThumbs
            },
        });

        galleryThumbs.slideTo(0, false, false);
        galleryTop.slideTo(0, false, false);
    }


    let swiperSpecialOffer = new Swiper('.special-offer', {
        slidesPerView: 1,
        // pagination: {
        //     el: '.swiper-pagination',
        //     type: 'fraction',
        //     formatFractionCurrent: function (number) {
        //         if (number < 10) {
        //             number = '0' + number;
        //         }
        //         return number;
        //     },
        //     formatFractionTotal: function (number) {
        //         if (number < 10) {
        //             number = '0' + number;
        //         }
        //         return number;
        //     },
        // },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    let recProducts = new Swiper('.rec-products__container', {
        slidesPerView: 1,
        simulateTouch: false,
        loop: true,
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction',
            formatFractionCurrent: function (number) {
                if (number < 10) {
                    number = '0' + number;
                }
                return number;
            },
            formatFractionTotal: function (number) {
                if (number < 10) {
                    number = '0' + number;
                }
                return number;
            },
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            768: {
                loop: true,
                slidesPerView: 3,
            },
            1025: {
                loop: true,
                slidesPerView: 4,
            },
            1200: {
                loop: false,
                slidesPerView: 5,
            },
        },
    });


    $('.pop-ups-leave-comment__wrap').on('submit', function (e) {
        e.preventDefault();

        axios.post(`/reviews`, $(this).serialize())
            .then(res => {
                $(this).find(".pop-ups__status").addClass("is-active");
                $(this)[0].reset();
            })
            .catch(error => console.error(error))
    })

    $('.pop-ups__get-discount__wrap').on('submit', function (e) {
        e.preventDefault();

        axios.post(`/discount-request`, $(this).serialize())
            .then(res => {
                $(this).find(".pop-ups__status").addClass("is-active");
                $(this)[0].reset();
            })
            .catch(error => console.error(error))
    })

    $('.pop-ups__call-me__wrap').on('submit', function (e) {
        e.preventDefault();

        axios.post(`/call-request`, $(this).serialize())
            .then(res => {
                $(this).find(".pop-ups__status").addClass("is-active");
                $(this)[0].reset();
            })
            .catch(error => console.error(error))
    })

    $('.product__load-more__reviews').on('click', function () {
        let reviews = $('.review.hidden:lt(5)');
        reviews.removeClass('hidden')
        let reviewsCount = $('.card-product__info__nav__reviews').data('quantity')
        if ($('.review:not(.hidden)').length + 1 >= reviewsCount) $(this).remove()
    })

    let selectedColor = $('.card-product__js:not(.grouped)').find('.colors__color.is-active');
    if (selectedColor.length) {
        $(`.card-product__item__slider[data-fv_id=${$(selectedColor).data('id')}]`).addClass('is-active').siblings().removeClass('is-active')
    }

    $('.card-product__js:not(.grouped) .colors__color').on('click', function (e) {
        e.preventDefault();

        let prodWrapper = $(this).closest('.card-product__item');

        $(this)
            .addClass('is-active')
            .siblings().removeClass('is-active');

        prodWrapper.find('.card-product__item__slider').removeClass('is-active');
        prodWrapper.find(`[data-fv_id=${$(this).data('id')}]`)
            .addClass('is-active');

        initProductPageSlider();
        calculateProductPrice()
    })

    $('.card-product__item__characteristic .jq-selectbox__dropdown li').on('click', function () {
        calculateProductPrice()
    })

    initProductPageSlider();
    if ($('.card-product').hasClass('grouped')) {
        calculateGroupedProductPrice()
    } else {
        calculateProductPrice();
    }


    let contactPageItemsWrapper = $('.store--products')
    $.each(contactPageItemsWrapper, (key, el) => {
        let products = $(el).find('.product-item');
        if (products.length > 18) {
            for (let i = 18; i <= products.length - 1; i++) {
                products[i].classList.add('hidden')
            }
        }
    })

    $('.store--products .see-more').on('click', function (e) {
        e.preventDefault()
        $(this).closest('.store--products').find('.product-item').removeClass('hidden');
        $(this).remove()
    })
});
//замена картинки в слайдере карточки товара
$(".swiper-wrapper").on('click', '.card-product__item__slider__preview__img', function (e) {
    $('.card-product__item__slider.is-active .card-product__item__slider__full__img').find('img').attr('src', $(this).attr('data-src'))
});
//ховеры в слайдере в слайдере карточки товара
$('.swiper-wrapper').on('click', '.card-product__item__slider__preview__img', function () {
    $(this).siblings().removeClass('is-active')
    $(this).siblings(`[data-swiper-slide-index=${$(this).attr('data-swiper-slide-index')}]`).addClass('is-active')
    $(this).addClass('is-active')
})

//замена картинки в слайдере Контактов
$(".contacts-slider .swiper-wrapper").on('click', '.swiper-slide', function (e) {
    // $(".store--slider__main-img").find('img').attr('src', $(this).attr('data-src'));
    $(this).closest(".store--slider").find('.store--slider__main-img img').attr('src', $(this).attr('data-src'));
});
//ховекры в слайдере в слайдере Контактов
$('.contacts-slider .swiper-wrapper').on('click', '.swiper-slide', function () {
    $(this).siblings().removeClass('is-active')
    $(this).siblings(`[data-swiper-slide-index=${$(this).attr('data-swiper-slide-index')}]`).addClass('is-active')
    $(this).addClass('is-active')
})

function setSwiperProperties(prop) {
    $(".products__container.swiper-container").css({'overflow': prop});
}

//инициализация слайдера на разрешение
const filterBreakpoint = window.matchMedia('(min-width:767px)');
const filterBreakpointChecker = function () {
    if (filterBreakpoint.matches === true) {
        $(".filter--wrap").css("display", "block");
    } else if (filterBreakpoint.matches === false) {
        $(".filter--wrap").css("display", "");
    }
};
filterBreakpoint.addListener(filterBreakpointChecker);
filterBreakpointChecker();

$(window).on('load', function () {
    // alert($(window).width());
    //ховер в слайдерах
    if ($(window).width() > 1200) {
        setSwiperProperties('inherit');
        $(".rec-products__container.swiper-container").css({'overflow': 'inherit'});
    } else {
        setSwiperProperties('hidden');
        $(".rec-products__container.swiper-container").css({'overflow': 'hidden'})
    }
});
const breakpoint = window.matchMedia('(min-width:1025px)');
// keep track of swiper instances to destroy later
let mySwiper;

const breakpointChecker = function () {
    // if larger viewport and multi-row layout needed
    if (breakpoint.matches === true) {
        // clean up old instances and inline styles when available
        if (mySwiper !== undefined) {
            $.each(mySwiper, function (key, el) {
                el.destroy(true, true);
            });
            setSwiperProperties('inherit');
        }
        // mySwiper.destroy( true, true );
        // or/and do nothing
        // else if a small viewport and single column layout needed
    } else if (breakpoint.matches === false) {
        // fire small viewport version of swiper
        return enableSwiper();

    }
};

const enableSwiper = function () {
    mySwiper = new Swiper('.products__container', {
        slidesPerView: 1,
        loop: true,
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction',
            formatFractionCurrent: function (number) {
                if (number < 10) {
                    number = '0' + number;
                }
                return number;
            },
            formatFractionTotal: function (number) {
                if (number < 10) {
                    number = '0' + number;
                }
                return number;
            },
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            768: {
                slidesPerView: 3,
            },
        },
    });
    setSwiperProperties('hidden')
};
// keep an eye on viewport size changes
breakpoint.addListener(breakpointChecker);
// kickstart
breakpointChecker();

//попапы
function popUpActive() {
    $('body').addClass("pop-up-active");
    $('.pop-ups-overlay').addClass("is-active");
    $('.pop-ups').addClass("is-active");
    // $('.pop-ups--wrap').addClass("is-active");
}

function popUpClose() {
    $('body').removeClass("pop-up-active");
    $('.pop-ups-overlay').removeClass("is-active");
    $('.pop-ups').removeClass("is-active");
    $('.pop-ups--wrap').removeClass("is-active");
    $('.pop-ups-leave-comment__wrap__status').removeClass("is-active");
}

$(document).mouseup(function (event) {

    let modal = $(".pop-ups__call-me__wrap , .pop-ups-leave-comment__wrap , .pop-ups__get-discount__wrap , .pop-ups__product-add__wrap");
    if (!modal.is(event.target) &&
        modal.has(event.target).length === 0) {
        popUpClose();
    }

});

new Vue().$mount('#catalog');
new Vue().$mount('#sale');

function initProductPageSlider() {
    let sliderHtml = $('.card-product__item__slider__preview:visible');
    if (!sliderHtml.hasClass('swiper-container-initialized'))
        new Swiper(sliderHtml, {
            slidesPerView: 3,
            spaceBetween: 10,
            // loop: true,
            slideToClickedSlide: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                576: {
                    slidesPerView: 4,
                    spaceBetween: 15,
                },
                1025: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                },
            }
        });
}

function contactNavigationHiden() {
    if ($(window).width() >= 1366) {
        if ($('.contacts-slider:visible .swiper-slide').length < 10) {
            // console.log("меньше 10");
            $('.store--slider__swiper .swiper-button-prev:visible').addClass("none");
            $('.store--slider__swiper .swiper-button-next:visible').addClass("none");
        }
    }
    if ($(window).width() >= 1025) {
        if ($('.contacts-slider:visible .swiper-slide').length < 7) {
            // console.log("меньше 7");
        }
    }
    if ($(window).width() >= 767) {
        if ($('.contacts-slider:visible .swiper-slide').length < 5) {
            // console.log("меньше 5");
        }
    }
    if ($(window).width() >= 575) {
        if ($('.contacts-slider:visible .swiper-slide').length < 4) {
            // console.log("меньше 4");
        }
    }
    if ($(window).width() >= 574) {
        if ($('.contacts-slider:visible .swiper-slide').length < 3) {
            // console.log("меньше 3");
        }
    }
}

function contactsSliderInitialization() {
    contactNavigationHiden();

    let _el = $('.contacts-slider:visible');
    if (_el.hasClass('swiper-container-initialized')) {
        return
    }

    new Swiper(_el, {
        spaceBetween: 10,
        slidesPerView: 3,
        // loop: true,
        slideToClickedSlide: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            576: {
                slidesPerView: 4,
            },
            768: {
                spaceBetween: 15,
                slidesPerView: 5,
            },
            1024: {
                slidesPerView: 7,
            },
            1365: {
                spaceBetween: 20,
                slidesPerView: 10,
            },
        }
    });
}
